import './App.css';
import axios from "axios";
import {useState, useEffect, Fragment} from "react";
import ReactJson from "react-json-view"

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    maxRedirects: 0
})

// Example POST method implementation:
async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response; // parses JSON response into native JavaScript objects
}


async function getTokenAsync(setState) {
    console.debug("Getting Token")
    try {
        let response = await instance.post("/token")
        // let response = await postData(process.env.REACT_APP_BASE_URL+"token")
        setState(response)
    } catch (e) {
        console.error(e)
    }
}
async function getHeaderAsync(setState) {
    console.debug("Getting Header")
    let response = await instance.get("/header")
    setState(response)

}

function getDateTime(){
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    return  cDate + ' ' + cTime;
}

let interval
let initSetup = false
function initialSetup(setState){
    console.log("Ran initial setup")
    if (!initSetup){
        instance.get("/").then(()=>setState(getDateTime()))
        interval = setInterval(async ()=>{
            await instance.get("/")
            setState(getDateTime())
        }, 30000)
        initSetup = true
    }
}


function App() {
    const [currToken, setCurrToken] = useState({"anything": "no"})
    const [currHeader, setCurrHeader] = useState({"anything": "no"})
    const [backendCallMade, setBackendCallMade] = useState("")
    useEffect( () => {
        initialSetup(setBackendCallMade)
    }, [])
    return (
        <Fragment className="App">
            <body className="App-header">
                <p>Token request last made at {backendCallMade}</p>
                <section id="token">
                    <button onClick={() => getTokenAsync(setCurrToken)}>
                        Get Token
                    </button>
                    <ReactJson
                        src={currToken}
                        theme="pop"

                    />
                </section>
                <section id="header">
                    <button onClick={() => getHeaderAsync(setCurrHeader)}>
                        Get Header
                    </button>
                    <ReactJson
                        src={currHeader}
                        theme="pop"
                    />
                </section>
            </body>
        </Fragment>
    );
}

export default App;
